import React, { Component } from "react";
import "./Home.css";

export default class Home extends Component {
  render() {
    return (
      <div className="Home">
        <div className="lander">
          <h1>TradeShare Club</h1>
          <p>Trade and share investment information in your own club </p>
        </div>
      </div>
    );
  }
}