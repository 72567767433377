import React from "react";
import { Route,  Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import NewTrade from "./containers/NewTrade";
import ResetPassword from "./containers/ResetPassword"
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import ChangeEmail from "./containers/ChangeEmail";
import AppliedRoute from "./components/AppliedRoute";
import Settings from "./containers/Settings";
import ChangePassword from "./containers/ChangePassword";

export default ({childProps}) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps}/>
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps}/>
    <UnauthenticatedRoute path="/resetpassword" exact component={ResetPassword} props={childProps}/>
    <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
    <AuthenticatedRoute path="/trades/new" exact component={NewTrade} props={childProps} />
    <AuthenticatedRoute path="/settings/email" exact component={ChangeEmail} props={childProps}/>
    <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps} />
    <AuthenticatedRoute path="/settings/password" exact component={ChangePassword} props={childProps}/>

    <Route component={NotFound} />
  </Switch>;
