import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./NewTrade.css";
import { API } from "aws-amplify";


export default class NewTrade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      segment: "",
      instrument: "",
      openPrice: "",
      closePrice: "",
      quantity: "",
      tradeType: ""
    };
  }

  validateForm() {
    return true; //this.state.segment.length > 0 && this.state.instrument.length > 0 &&  this.state.openPrice.length > 0 && this.state.quantity.length > 0 && this.state.tradeType.length > 0 ;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

    handleSubmit = async event => {
      event.preventDefault();

      this.setState({ isLoading: true });

      try {
        await this.createTrade({
          userId: "1",
          segment: this.state.segment,
          instrument: this.state.instrument,
          openPrice: this.state.openPrice,
          closePrice: this.state.closePrice,
          quantity: this.state.quantity,
          tradeType: this.state.tradeType
        });
        this.props.history.push("/");
      } catch (e) {
        console.log("in catch with this error " + e);
        alert(e);
        this.setState({ isLoading: false });
      }
    }

    createTrade(trade) {
      return API.post("trades", "/trades", {
        body: trade
      });
    }


   render() {
    return (
      <div className="NewTrade">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="segment">
          <ControlLabel>Segment</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.content}
              componentClass="select"
            >
            <option value=""></option>
            <option value="crypto">Crypto Currencies</option>
            <option value="currencies">Currencies</option>
            <option value="commodities">Commodities</option>
            <option value="indices">Indices</option>
          </FormControl>
          </FormGroup>
          <FormGroup controlId="instrument">
          <ControlLabel>Instrument</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.content}
              componentClass="select"
            >
            <option value=""></option>
            <option value="FTSE100">FTSE100</option>
            <option value="GBPUSD">GBPUSD</option>
            <option value="Gold">Gold</option>
            <option value="BitCoin">BitCoin</option>
            </FormControl>
          </FormGroup>
          <FormGroup controlId="openPrice">
          <ControlLabel>Open Price</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.content}
            />
          </FormGroup>
          <FormGroup controlId="closePrice">
          <ControlLabel>Close Price</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.content}
              componentClass="input"
            />
          </FormGroup>
          <FormGroup controlId="quantity">
          <ControlLabel>Quantity</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.content}
              componentClass="input"
            />
          </FormGroup>
          <FormGroup controlId="tradeType">
          <ControlLabel>Trade Type</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.content}
              componentClass="select"
            >
            <option value=""></option>
            <option value="buy">Buy/Long</option>
            <option value="sell">Sell/Short</option>
          </FormControl>
          </FormGroup>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create New Trade"
            loadingText="Creating…"
          />
        </form>
      </div>
    );
  }
}
