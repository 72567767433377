export default {
  s3: {
    REGION: "eu-west-2",
    BUCKET: "tradeshare.club"
  },
  apiGateway: {
    REGION: "eu-west-2",
    URL: "https://pm6q097c1g.execute-api.eu-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_Z0WbEYnCI",
    APP_CLIENT_ID: "aalq74p3le55s64ogq9nuivaa",
    IDENTITY_POOL_ID: "eu-west-2:8e2a34e4-c795-49de-b476-d42cbba5a423"
  }
};